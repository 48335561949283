import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/common/page-title";

const Teachers = () => (
  <Layout>
    <Seo title="Teachers" />
    <div>
      <PageTitle name="Our Teachers" />
      <p className="text-base leading-6 text-gray-700 pb-6">
        Whether you want to improve your practice or start a career in Yoga, at
        AAYAA Yoga we respect your desire to learn and promise you of a highly
        gratifying course taught by the best teachers, devised by numerous yogic
        brains, and seasoned with time and experience. Our panel of teachers
        includes highly qualified yoga experts and teachers from different
        countries, of different age, from different cultural and professional
        backgrounds.
      </p>
      <p className="text-base leading-6 text-gray-700 pb-6">
        At AAYAA our belief is that everyone is unique, and we value Yog as a
        "system beyond systems" – Yoga is not about any dogma. Our major aim is
        to explain yogic practices from various perspectives – philosophical,
        scientific, cultural, spiritual… and to let our students understand and
        teach them safely and with the proper knowledge, also incorporating in
        it the ancient principles of Ayurved after all AAYAA is "All About Yoga
        And Ayurveda."
      </p>
      {teachers.map((item) => {
        return (
          <figure
            className="md:flex md:flex-row rounded-xl p-8 md:p-0 mb-12"
            key={item.id}
          >
            <div className="text-center my-auto mx-auto rounded">
              {item.id === 1 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/jagjeet-singh.jpg"
                  alt="Jagjeet Singh"
                />
              )}

              {item.id === 2 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/shanti.jpg"
                  alt="Shanti"
                />
              )}

              {item.id === 3 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/via-vamala.jpg"
                  alt="Via Vamala"
                />
              )}

              {item.id === 4 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/nyoman-ida-trini.webp"
                  alt="Nyoman Ida Trini"
                />
              )}

              {item.id === 5 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/pankaj-rawat.webp"
                  alt="Pankaj Rawat"
                />
              )}

              {item.id === 6 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/shubham-unigal.webp"
                  alt="Shubham Unigal"
                />
              )}

              <figcaption className="font-medium mt-4">
                <div className="text-orange-500 uppercase">{item.name}</div>
              </figcaption>
            </div>

            <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
              <blockquote>
                <p className="text-gray-700 leading-relaxed">
                  {item["about-1"]}
                </p>
                <p className="text-gray-700 leading-relaxed mt-2">
                  {item["about-2"]}
                </p>
              </blockquote>
            </div>
          </figure>
        );
      })}
    </div>
  </Layout>
);

const teachers = [
  {
    id: 1,
    name: "Jagjeet Singh",
    image: "../images/teachers/jagjeet-singh.jpg",
    "about-1":
      "Jagjeet Singh, a philosophy and pranayama specialist, was born in Haridwar and afterwards relocated to Rishikesh. He began practicing yoga when he was very young since he comes from a family of yoga enthusiasts. He grew up reading the Bhagavad Gita questions about life.",
    "about-2":
      "He learnt yoga as a youngster from his grandpa, who was a well-known yogi at the time. He received his official education from Gurukul Kangri University, where he earned an M.Sc in Yoga years in the Himalayas in pursuit of the masters. During his traveling days, he learned a great deal about yoga from a variety of gurus. He has been practicing yoga therapy, marma therapy, alternative therapy, yoga asana, pranayama, and mudra bandha as aresult of his mastery of hatha yoga.",
  },
  {
    id: 2,
    name: "YOGINI SHANTI",
    image: "../images/teachers/shanti.jpg",
    "about-1":
      "Yoga teacher Yogini Shanti has been teaching for years and teaches meditation, yoga philosophy, and pranayama. She has completed various yoga courses including (500 hour YTTC, masters in yoga, meditation course, and yoga philosophy course) from Bali Yoga School and Swami Vivekananda yoga Anusandhana Samsthana Higher Educational Institution in Bangalore, India.",
    "about-2": "",
  },
  {
    id: 3,
    name: "VIA VAMALA",
    image: "../images/teachers/via-vamala.jpg",
    "about-1":
      "Via Vamala began doing yoga to help her physical body recover at first. Obesity, hormonal imbalances producing profound pain in the womb, digestive disorders, and sleep disorders plagued her life, bringing a great deal of uncertainty and worry. The majority of these illnesses do not manifest themselves until they have progressed to a critical stage. So she began attending yoga lessons on the spur of the moment, and each time she did, she felt a little better.",
    "about-2":
      "Until her instructor invites her to join the Ashram and learn more about yoga philosophy. As a result, she spent eight months at the Ashram. After spending time in the Ashram, she learn fresh perspective on life. She will never return to her former habits. As a result, she chose to devote her time to yoga. She promotes the well-being of our human life experience by comprehending the essence of our own existence via yoga. By allowing ourselves to be exposed to life, we may learn and grow. Life is such a wonderful experience; it is a gift that is showered onto us.",
  },
  {
    id: 4,
    name: "Nyoman Ida Trini",
    image: "../images/teachers/nyoman-ida-trini.jpg",
    "about-1":
      "Ida has been teaching yoga since last 6 years and is well trained in Yin, Hatha, Aerial Yoga. Her passion along with her dedication makes classes with her informative and fun.",
    "about-2":
      "She will be taking asanas, Alignment, teaching practice, teaching methodology, arrangement and all aspects of asana teaching.",
  },
  {
    id: 5,
    name: "Pankaj Rawat",
    image: "../images/teachers/pankaj-rawat.jpg",
    "about-1":
      "Started his Yogic journey in 2011 from the World's Yoga capital Rishikesh, Yogi Pankaj Rawat Ji had successfully completed his Post-Graduate Diploma in Yoga Science from Uttarakhand Sanskrit University instituted in Haridwar, India. It was in his gene to enjoy and explore the great ancient art of yoga Yogi Pankaj Rawat ji studied and practiced Yoga under the able guidance of famous and experienced Yog Gurus.",
    "about-2":
      "Along with his yoga study, he also assists many Yog Gurus with their classes. The classes conducted by him is an amalgam of practical demonstration, theory involved and discussion",
  },
  {
    id: 6,
    name: "Shubham Uniyal",
    image: "../images/teachers/shubham-unigal.jpg",
    "about-1":
      " Shubham Uniyal was born and brought up in the Himalayan range of Uttarakhand for the past 22 years he has been living in Rishikesh and has done his post-graduation in Yogic Science From Shree Jai Ram Sanskrit Mahavidyalaya and recently he  has qualified the UGC net examination.",
    "about-2":
      "He is  trained in classical hatha yoga and his areas of expertise include Yogic philosophy, Bhagavad Geeta, Human Anatomy and Physiology, Meditation, Shatkarma, Pranayam, and Hatha Yoga.",
  },
];

export default Teachers;
